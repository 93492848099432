import React, { useState, useMemo, useCallback } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import routes from './routers'
import { ACCESS_TOKEN } from './constants'
import { LoginContext, SignUpContext } from './context'
import { MobileMenuContext } from './context/mobileMenuContext'
import usePreventBodyScroll from '../src/hooks/usePreventBodyScroll'

const App = () => {
	const [email, setEmail] = useState('')
	const [logedIn, setLogedIn] = useState(false)
	const [menuIsOpened, setMenuIsOpened] = useState(false)
	usePreventBodyScroll(menuIsOpened);

	const handleToggleMenu = useCallback(
		() => setMenuIsOpened((prev) => !prev),
		[]
	)

	const handleLogOut = useCallback(() => {
		localStorage.removeItem('access_token')
		window.location.href = 'https://dealgen.net'
		// setLogedIn(false)
	}, [])

	const value = useMemo(() => ({ email, setEmail }), [email])
	const member = useMemo(
		() => ({ logedIn, setLogedIn, handleLogOut }),
		[logedIn]
	)

	const menuMemoizedValue = useMemo(() => ({
		handleToggleMenu, menuIsOpened
	}),[menuIsOpened])

	const renderElement = (element, path, isPrivate) => {
		if (logedIn || localStorage.getItem(ACCESS_TOKEN)) {
			return path === '/login' || path === '/signup' ? (
				<Navigate replace to="/deals" />
			) : (
				element
			)
		}
		if (!logedIn && !localStorage.getItem(ACCESS_TOKEN)) {
			return isPrivate ? <Navigate replace to="/" /> : element
		}
	}

	return (
		<SignUpContext.Provider value={value}>
			<LoginContext.Provider value={member}>
				<MobileMenuContext.Provider value={menuMemoizedValue}>
					<React.StrictMode>
						<React.Suspense fallback={<span>Loading ...</span>}>
							<Routes>
								{routes.map((route) => {
									const { exact, path, element, isPrivate } =
										route
									return (
										<Route
											key={path}
											exact={exact}
											path={path}
											element={renderElement(
												element,
												path,
												isPrivate
											)}
										/>
									)
								})}
								<Route
									path="*"
									element={<Navigate replace to="/" />}
								/>
							</Routes>
						</React.Suspense>
					</React.StrictMode>
				</MobileMenuContext.Provider>
			</LoginContext.Provider>
		</SignUpContext.Provider>
	)
}

export default App
